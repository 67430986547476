import { globalSettings } from "services/globalSettings/globalSettingsService";
export default function MobileNavbarContentStyle() {
  return (
    <style jsx global>{`
      .mobile-navigation-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        .drawer-toggle {
          color: ${globalSettings.navigation.navigationMobileToggleColor};
        }
        .search-bar {
          width: 80%;
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
        }
        @media (min-width: ${globalSettings.responsive.breakpointdesktop}px) {
          display: ${globalSettings.navigation.alwaysUseMobileView
            ? "flex"
            : "none"};
        }

        @media screen and (hover: none) {
          display: flex;
        }
      }
    `}</style>
  );
}
