import IconExpandLess from "@mui/icons-material/ExpandLess";
import IconExpandMore from "@mui/icons-material/ExpandMore";
import { Collapse, Divider, List, ListItemText } from "@mui/material/";
import { useRouter } from "next/router";
import React from "react";
import { getRouteFromUrl, isNavLinkActive } from "utils/clientUtil";
import { getPageUrlOrRootUrl } from "utils/util";
import DrawerListMenuItemComponent from "./drawerListMenuItemComponent";

const DrawerListMenuItem = ({ navItem, onClick }) => {
  const { name, page, externalUrl, subPages = [] } = navItem;
  const isExpandable = subPages && subPages.length > 0;
  const [open, setOpen] = React.useState(false);
  const router = useRouter();

  function handleClick() {
    setOpen(!open);
  }

  return navItem.page || externalUrl ? (
    // replace the entire ternary above with this to prevent rendering root page in the navigation
    // old ternary (without root page):
    // navItem.page &&
    // navItem.page.url !== process.env.NEXT_PUBLIC_ROOT_PAGE_URL) ||
    // externalUrl ?
    <>
      <DrawerListMenuItemComponent
        className={`${
          isNavLinkActive(
            router,
            null,
            navItem.page
              ? navItem.page.url
              : navItem.url
              ? navItem.url
              : getRouteFromUrl(navItem.externalUrl)
          )
            ? "active "
            : ""
        }drawer-list-menu-item`}
        link={
          !isExpandable
            ? page
              ? getPageUrlOrRootUrl(page.url)
              : externalUrl
            : null
        }
        isExternalLink={Boolean(externalUrl)}
        onClick={handleClick}
        closeDrawer={onClick}
      >
        <ListItemText primary={name} />
        {isExpandable && !open && <IconExpandMore />}
        {isExpandable && open && <IconExpandLess />}
      </DrawerListMenuItemComponent>
      {/* Display the expand menu if the item has children */}
      {isExpandable ? (
        <Collapse in={open} timeout="auto" unmountOnExit>
          <Divider />
          <List component="div" disablePadding>
            {[{ ...navItem, subPages: [] }, ...subPages].map((item, index) => (
              <DrawerListMenuItem
                navItem={item}
                onClick={onClick}
                key={index}
              />
            ))}
          </List>
        </Collapse>
      ) : null}
    </>
  ) : null;
};

export default DrawerListMenuItem;
