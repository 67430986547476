import MenuIcon from "@mui/icons-material/Menu";
import { SwipeableDrawer } from "@mui/material";
import useToggle from "hooks/useToggle";
import useWindowSize from "hooks/useWindowSize";
import { useTranslation } from "next-i18next";
import dynamic from "next/dynamic";
import { useState } from "react";
import { globalSettings } from "services/globalSettings/globalSettingsService";
import { getColorVariableName, optimizedImage } from "utils/util";
import DrawerContent from "./drawerMenu/drawerContent";
import MobileNavbarContentStyle from "./mobileNavbarContentStyle";

const Box = dynamic(() => import("@mui/material/Box"));
const IconButton = dynamic(() => import("@mui/material/IconButton"));
const SearchIcon = dynamic(() => import("@mui/icons-material/Search"));
const NavUserIcon = dynamic(() => import("../navUserIcon"));
const NavbarBrand = dynamic(() => import("../navbarBrand"));
const LanguageSelector = dynamic(() =>
  import("components/localization/languageSelector/languageSelector")
);
const SearchBar = dynamic(() =>
  import("components/search/searchBar/searchBar")
);

const MobileNavbarContent = (props) => {
  const { width } = useWindowSize();
  const [searchActive, toggleSearchActive] = useToggle(false);
  const drawerDirection = globalSettings.navigation.mobileDrawerDropDirection
    ? globalSettings.navigation.mobileDrawerDropDirection
    : "left";
  const [isDirectionOpen, setIsDirectionOpen] = useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
  const { t: tPublic } = useTranslation("public");
  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setIsDirectionOpen({ ...isDirectionOpen, [anchor]: open });
  };
  const isDesktop = globalSettings?.responsive?.breakpointdesktop < width;

  return (
    <>
      {searchActive ? (
        <SearchBar
          active={searchActive}
          onSubmit={() => toggleSearchActive(false)}
          toggleSearch={() => toggleSearchActive(false)}
        />
      ) : null}

      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          visibility: searchActive ? "hidden" : "visible",
        }}
      >
        {globalSettings.navigation.navLogoPositionRight ? (
          <IconButton
            className="drawer-toggle"
            size="large"
            aria-label={tPublic("mobile-navbar-toggle")}
            aria-controls="menu-appbar"
            aria-expanded={Object.values(isDirectionOpen).some(
              (directionOpen) => directionOpen
            )}
            aria-haspopup="true"
            onClick={toggleDrawer(drawerDirection, true)}
            color="inherit"
          >
            <MenuIcon sx={{ width: "1.5em", height: "1.5em" }} />
          </IconButton>
        ) : (
          <NavbarBrand
            mlogo={
              props.navigation && props.navigation.mlogo
                ? optimizedImage(props.navigation.mlogo, true, 400)
                : null
            }
            url={
              props.navigation && props.navigation.logoUrl
                ? props.navigation.logoUrl
                : null
            }
            width={isDesktop ? globalSettings?.navigation?.navLogoWidth : globalSettings?.navigation?.navLogoWidthMobile}
            height={isDesktop ? globalSettings?.navigation?.navLogoHeight : globalSettings?.navigation?.navLogoHeightMobile}
          />
        )}
        {globalSettings.languageswitch.visible &&
        !globalSettings.navigation.languageSelectorRight ? (
          <LanguageSelector />
        ) : null}
        {!globalSettings.navigation.searchIconRight ? (
          <IconButton
            aria-label={tPublic("search")}
            onClick={() => toggleSearchActive(true)}
            className="search-button-mobile"
          >
            <SearchIcon
              alt={tPublic("search")}
              fontSize="large"
              style={{
                fill: `var(${getColorVariableName(
                  globalSettings.search.searchIconInNavColor
                )})`,
              }}
            />
          </IconButton>
        ) : null}
      </Box>

      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          visibility: searchActive ? "hidden" : "visible",
        }}
      >
        {globalSettings.navigation.searchIconRight ? (
          <IconButton
            aria-label={tPublic("search")}
            onClick={() => toggleSearchActive(true)}
            className="search-button-mobile"
          >
            <SearchIcon
              alt={tPublic("search")}
              fontSize="large"
              style={{
                fill: `var(${getColorVariableName(
                  globalSettings.search.searchIconInNavColor
                )})`,
              }}
            />
          </IconButton>
        ) : null}

        {globalSettings.languageswitch.visible &&
        globalSettings.navigation.languageSelectorRight ? (
          <LanguageSelector />
        ) : null}

        {globalSettings.navigation.navLogoPositionRight ? (
          <NavbarBrand
            mlogo={
              props.navigation && props.navigation.mlogo
                ? optimizedImage(props.navigation.mlogo, true, 400)
                : null
            }
            url={
              props.navigation && props.navigation.logoUrl
                ? props.navigation.logoUrl
                : null
            }
            width={isDesktop ? globalSettings?.navigation?.navLogoWidth : globalSettings?.navigation?.navLogoWidthMobile}
            height={isDesktop ? globalSettings?.navigation?.navLogoHeight : globalSettings?.navigation?.navLogoHeightMobile}
          />
        ) : (
          <IconButton
            className="drawer-toggle"
            size="large"
            aria-label={tPublic("mobile-navbar-toggle")}
            aria-controls="menu-appbar"
            aria-haspopup="true"
            aria-expanded={Object.values(isDirectionOpen).some(
              (directionOpen) => directionOpen
            )}
            onClick={toggleDrawer(drawerDirection, true)}
            color="inherit"
          >
            <MenuIcon sx={{ width: "1.5em", height: "1.5em" }} />
          </IconButton>
        )}
        <NavUserIcon
          icon={globalSettings.navigation.userIcon}
          width={globalSettings.navigation.userIconWidthAndHeightMobile || 32}
          height={globalSettings.navigation.userIconWidthAndHeightMobile || 32}
        />
      </Box>

      <SwipeableDrawer
        PaperProps={{ className: "mobile-navbar-drawer" }}
        style={{ width: "100%", maxWidth: "100%" }}
        anchor={drawerDirection}
        open={isDirectionOpen[drawerDirection]}
        onClose={toggleDrawer(drawerDirection, false)}
        onOpen={toggleDrawer(drawerDirection, true)}
        // if swiping to open is not wanted
        // disableSwipeToOpen
      >
        <DrawerContent
          navigation={props.navigation}
          toggleDrawer={toggleDrawer(drawerDirection, false)}
        />
      </SwipeableDrawer>
      <MobileNavbarContentStyle />
    </>
  );
};
export default MobileNavbarContent;
