import { globalSettings } from "../../services/globalSettings/globalSettingsService";
import { useRouter } from "next/router";
import DrawerListMenuItemComponent from "./mobile/drawerMenu/drawerListMenuItemComponent";
import { ListItemText } from "@mui/material";
import { useDispatch } from "react-redux";
import { setShowPwaPrompt } from "../../store/actions";
import { useEffect, useState } from "react";

export default function PwaNavLink(props) {
  const router = useRouter();
  const dispatch = useDispatch();

  const navLinkActive = globalSettings.navigation?.pwaNavLink
    ? globalSettings.navigation.pwaNavLink
    : false;

  const listItemText = globalSettings.navigation?.pwaNavLinkText
    ? globalSettings.navigation.pwaNavLinkText[router.locale]
    : "";

  const isStandalone = () => {
    return window.matchMedia("(display-mode: standalone)").matches;
  };

  const handleClick = () => {
    dispatch(setShowPwaPrompt(true));
    props.toggleDrawer();
  };

  const [standalone, setStandalone] = useState(false);

  useEffect(() => {
    setStandalone(isStandalone());
  }, []);

  return (
    <>
      {navLinkActive && !standalone ? (
        <DrawerListMenuItemComponent
          className="drawer-list-menu-item"
          onClick={handleClick}
        >
          <ListItemText>{listItemText}</ListItemText>
        </DrawerListMenuItemComponent>
      ) : (
        <></>
      )}
    </>
  );
}
