import { globalSettings } from "services/globalSettings/globalSettingsService";
export default function DrawerContentStyle() {
  return (
    <style jsx global>{`
      @supports (-webkit-touch-callout: none) {
        body {
          position: fixed;
          width: 100%;
        }
      }

      .MuiPaper-root.mobile-navbar-drawer {
        background-color: ${globalSettings.navigation
          .navigationBackgroundColor};
        height: 100%;
        width: ${globalSettings.navigation.mobileNavigationDrawerWidth
          ? `${globalSettings.navigation.mobileNavigationDrawerWidth}`
          : "100%"};
        .mobile-nav-drawer-container {
          display: flex;
          flex-direction: column;

          .mobile-drawer-header-container {
            position: relative;
            z-index: 1;

            .search-bar {
              position: absolute;
              width: calc(100% - 40px);
              left: 20px;
              top: 50%;
              transform: translateY(-50%);
            }
            .mobile-drawer-header {
              display: flex;
              flex-grow: 1;
              // padding-left: 60px;
              width: 100%;
              padding-left: ${globalSettings.navigation.centerDrawerLogo
                ? "100px"
                : "0"};
              .brand-logo-wrapper {
                width: 100%;
                .navigation-brand {
                  justify-content: ${globalSettings.navigation.centerDrawerLogo
                    ? "center !important"
                    : "start !important"};
                }
              }

              .close-drawer-button {
                padding: 2px;
                svg {
                  width: 1.5em;
                  height: 1.5em;
                  color: ${globalSettings.navigation.baseLayerElementFontColor};
                }
              }
              .search-button-mobile {
                padding: 2px;
                svg {
                  width: 1em;
                  height: 1em;
                  color: ${globalSettings.navigation.baseLayerElementFontColor};
                }
              }

              .navigation-brand {
                margin-left: ${globalSettings.navigation.navLogoMarginX}px;
                margin-right: ${globalSettings.navigation.navLogoMarginX}px;
                margin-top: ${globalSettings.navigation.navLogoMarginY}px;
                margin-bottom: ${globalSettings.navigation.navLogoMarginY}px;
                justify-content: center;
              }
            }
          }

          .MuiList-root {
            .MuiListItemText-root {
              padding: 0;
            }
            // every button in drawer
            .MuiButtonBase-root {
              // no padding for left or right because of the indents
              padding-left: 2rem;
              padding-top: ${globalSettings.navigation
                .navbarElementPaddingYMobile}px;
              padding-bottom: ${globalSettings.navigation
                .navbarElementPaddingYMobile}px;

              background-color: ${globalSettings.navigation
                .baseLayerElementBackgroundColor};
              &:hover {
                background-color: ${globalSettings.navigation
                  .hoverElementBackgroundColor} !important;
                .MuiTypography-root,
                .MuiSvgIcon-root {
                  color: ${globalSettings.navigation
                    .hoverElementFontColor} !important;
                }
              }
              &.active {
                background-color: ${globalSettings.navigation
                  .activeElementBackgroundColor} !important;
                .MuiTypography-root,
                .MuiSvgIcon-root {
                  color: ${globalSettings.navigation
                    .activeElementFontColor} !important;
                }
              }

              .MuiTypography-root {
                font-size: ${globalSettings.navigation.fontSize}px;
                font-weight: ${globalSettings.navigation.fontWeight};
                display: -webkit-box;
                white-space: normal;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                overflow: hidden;
                color: ${globalSettings.navigation.baseLayerElementFontColor};
              }
              .MuiSvgIcon-root {
                color: ${globalSettings.navigation.baseLayerElementFontColor};
                width: 1em;
                height: 1em;
              }
            }
            // second level
            .MuiCollapse-root {
              background-color: ${globalSettings.navigation
                .notBaseLayerBackgroundColor};
              .MuiButtonBase-root {
                padding-left: 4rem;
                background-color: ${globalSettings.navigation
                  .notBaseLayerElementBackgroundColor};
                .MuiTypography-root {
                  color: ${globalSettings.navigation
                    .notBaseLayerElementFontColor};
                }
              }
              // third level
              .MuiCollapse-root {
                .MuiButtonBase-root {
                  padding-left: 6rem;
                }
              }
            }
            .MuiButtonBase-root {
              word-break: break-word;
            }
            .menuItem {
              padding-left: 2rem;
            }
          }
        }
      }
    `}</style>
  );
}
