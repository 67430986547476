import { ListItem } from "@mui/material";
import Link from "next/link";

const DrawerListMenuItemComponent = ({
  className,
  onClick,
  closeDrawer,
  link,
  children,
  isExternalLink,
}) => {
  // If link is not set return the orinary ListItem
  if (!link) {
    return (
      <ListItem button className={className} onClick={onClick}>
        {children}
      </ListItem>
    );
  }

  // Return a ListItem with a link component
  return isExternalLink &&
    !link.startsWith(process.env.NEXT_PUBLIC_NEXTJS_URL) ? (
    <ListItem
      button
      className={className}
      rel="nofollow noopener noreferrer"
      target="_blank"
      href={link}
      component="a"
    >
      {children}
    </ListItem>
  ) : (
    <Link href={link} passHref>
      <ListItem
        button
        onClick={closeDrawer}
        className={className}
        component="a"
      >
        {children}
      </ListItem>
    </Link>
  );
};

export default DrawerListMenuItemComponent;
