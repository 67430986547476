import { Close } from "@mui/icons-material";
import { List } from "@mui/material";
import NavUserIcon from "components/navigation/navUserIcon";
import NavbarBrand from "components/navigation/navbarBrand";
import useWindowSize from "hooks/useWindowSize";
import { useTranslation } from "next-i18next";
import dynamic from "next/dynamic";
import { globalSettings } from "services/globalSettings/globalSettingsService";
import { optimizedImage } from "utils/util";
import { CMS_FEATURE_PWA } from "../../../../utils/constants";
import CmsHasFeature from "../../../cms/cmsUtil/cmsHasFeature.js/cmsHasFeature";
import PwaNavLink from "../../pwaNavLink";
import DrawerContentStyle from "./drawerContentStyle";
import DrawerListMenuItem from "./drawerListMenuItem";

const Box = dynamic(() => import("@mui/material/Box"));
const IconButton = dynamic(() => import("@mui/material/IconButton"));

// https://codesandbox.io/s/github/modularcoder/tutorial-react-tree-menu/tree/master/stage4-react-router?from-embed=&file=/src/AppMenuItem.tsx:1588-1589
// example for drawer navigation
const DrawerContent = (props) => {
  const { t: tCms } = useTranslation("cms");
  const { width } = useWindowSize();

  const isDesktop = globalSettings?.responsive?.breakpointdesktop < width;

  return (
    <>
      <Box
        className="mobile-nav-drawer-container"
        role="presentation"
        id="menu-appbar"
      >
        <Box className="mobile-drawer-header-container">
          <Box className="mobile-drawer-header">
            <Box className="brand-logo-wrapper">
              <NavbarBrand
                mlogo={
                  props.navigation && props.navigation.mlogo
                    ? optimizedImage(props.navigation.mlogo, true, 400)
                    : null
                }
                url={
                  props.navigation && props.navigation.logoUrl
                    ? props.navigation.logoUrl
                    : null
                }
                width={isDesktop ? globalSettings?.navigation?.navLogoWidth : globalSettings?.navigation?.navLogoWidthMobile}
                height={isDesktop ? globalSettings?.navigation?.navLogoHeight : globalSettings?.navigation?.navLogoHeightMobile}
              />
            </Box>

            <Box
              sx={{ display: "flex", alignItems: "center", padding: "20px" }}
            >
              <NavUserIcon
                icon={globalSettings.navigation.userIcon}
                width={
                  globalSettings.navigation.userIconWidthAndHeightMobile || 32
                }
                height={
                  globalSettings.navigation.userIconWidthAndHeightMobile || 32
                }
              />
              <IconButton
                size="large"
                color="inherit"
                className="close-drawer-button"
                onClick={props.toggleDrawer}
              >
                <Close />
              </IconButton>
            </Box>
          </Box>
        </Box>

        <List component="nav" className={"appMenu"} disablePadding>
          {props.navigation && props.navigation.pages.length > 0
            ? props.navigation.pages.map((item, index) => (
                <DrawerListMenuItem
                  navItem={item}
                  onClick={props.toggleDrawer}
                  key={index}
                />
              ))
            : null}

          <CmsHasFeature feature={CMS_FEATURE_PWA} hidePlaceholderText>
            <PwaNavLink toggleDrawer={props.toggleDrawer} />
          </CmsHasFeature>
        </List>
      </Box>
      <DrawerContentStyle />
    </>
  );
};

export default DrawerContent;
